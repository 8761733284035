import React, { useState } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';
import { useAccessRequestCreate } from 'hooks/useAccessRequests';
import { SendOutlined } from '@mui/icons-material';
import useUserCompany from 'hooks/useUserCompany';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent={'center'}
      height={'100%'}
      spacing={2}
    >
      <Box>
        {t(
          'Collections are groups of companies, suppliers, or other partners.'
        )}
      </Box>
      <Button
        startIcon={<ArrowDownwardIcon />}
        variant="contained"
        color="primary"
        onClick={() => setSearchParams({ example: 'true' })}
      >
        {t('Show Example Collection')}
      </Button>
    </Stack>
  );
}

interface ScoreProps {
  score?: number; // score is optional and ranges from 0 to 100
}

const ScoreComponent: React.FC<ScoreProps> = ({ score = 0 }) => {
  // Normalize score to fit within a scale of 0-10
  const normalizedScore = Math.round((score / 100) * 10);

  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      {/* Display the numerical score */}
      <Typography variant="body1" sx={{ marginRight: 1, width: '24px' }}>
        {score}
      </Typography>

      {/* Display the score bar */}
      <Box display="flex">
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            width={6}
            height={16}
            borderRadius={1}
            marginRight={'2px'}
            bgcolor={
              index < normalizedScore ? colors.secondary300 : colors.neutral200
            }
          />
        ))}
      </Box>
    </Box>
  );
};

function QualatativeDescripterChip({
  type,
}: {
  type?: 'VERY_GOOD' | 'GOOD' | 'FAIR' | 'POOR';
}) {
  const { t } = useTranslation();
  return type ? (
    <Chip
      label={
        type === 'GOOD' ? t('High') : type === 'FAIR' ? t('Medium') : t('Low')
      }
      style={{
        borderRadius: 4,
        fontSize: '12px',
        color: colors.neutral600,
        height: '24px',
        backgroundColor:
          type === 'GOOD'
            ? colors.primary100
            : type === 'FAIR'
            ? colors.warning100
            : colors.tertiary100,
      }}
    />
  ) : null;
}

function UserCompanyCollectionList({
  collectionId,
  useFakeData,
}: {
  collectionId?: string;
  useFakeData?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { userCompany } = useUserCompany();
  const [searchParams] = useSearchParams();

  const showFakeData = searchParams.get('example') === 'true';
  const { mutate: createRequest, isLoading: isLoadingCreate } =
    useAccessRequestCreate();

  const [page, setPage] = React.useState(0);
  const { data, isLoading, refetch, isRefetching } =
    useUserCompanyCollectionOverview({
      page: page + 1,
      collection_id: collectionId,
    });

  const dedupeByName = (data: any[]): any[] => {
    const seenNames = new Set<string>();
    return data.filter((item) => {
      if (seenNames.has(item.company_name)) {
        return false;
      }
      seenNames.add(item.company_name);
      return true;
    });
  };

  const rowCount = data ? dedupeByName(data).length : 0;
  const rows =
    useFakeData && showFakeData
      ? [
          {
            id: Math.random(),
            company_internal_supplier_id: null,
            company_internal_supplier_name: null,
            company_name: 'Acme Corp',
            company_slug: 'test-company-214',
            company_maturity_level_crc: 'FAIR',
            company_climate_score_overall: 6.1,
            company_public_data_screening_status: 'COMPLETED',
            company_public_data_screening_last_date: '2024-11-14',
            company_private_data_status: 'ACCESS_REQUESTED',
            reporting_company_internal_supplier_id: null,
            reporting_company_internal_supplier_name: null,
            reporting_company_name: null,
            reporting_company_slug: null,
            reporting_company_maturity_level_crc: null,
            reporting_company_climate_score_overall: null,
            reporting_company_public_data_screening_status: 'IN_PROGRESS',
            reporting_company_public_data_screening_last_date: null,
            reporting_company_private_data_status: 'ACCESS_CAN_BE_REQUESTED',
          },
          {
            id: Math.random(),
            company_internal_supplier_id: null,
            company_internal_supplier_name: null,
            company_name: 'Wisozk Ltd',
            company_slug: 'test-company-214',
            company_maturity_level_crc: 'LOW',
            company_climate_score_overall: 0,
            company_public_data_screening_status: null,
            company_public_data_screening_last_date: null,
            company_private_data_status: null,
            reporting_company_internal_supplier_id: null,
            reporting_company_internal_supplier_name: null,
            reporting_company_name: null,
            reporting_company_slug: null,
            reporting_company_maturity_level_crc: null,
            reporting_company_climate_score_overall: null,
            reporting_company_public_data_screening_status: null,
            reporting_company_public_data_screening_last_date: null,
            reporting_company_private_data_status: null,
          },
          {
            id: Math.random(),
            company_internal_supplier_id: null,
            company_internal_supplier_name: null,
            company_name: 'Concrete Co.',
            company_slug: 'test-company-214',
            company_maturity_level_crc: 'POOR',
            company_climate_score_overall: 3.9,
            company_public_data_screening_status: 'IN_PROGRESS',
            company_public_data_screening_last_date: null,
            company_private_data_status: 'ACCESS_CAN_BE_REQUESTED',
            reporting_company_internal_supplier_id: null,
            reporting_company_internal_supplier_name: null,
            reporting_company_name: 'Concrete Group',
            reporting_company_slug: 'concrete-group',
            reporting_company_maturity_level_crc: null,
            reporting_company_climate_score_overall: null,
            reporting_company_public_data_screening_status: 'IN_PROGRESS',
            reporting_company_public_data_screening_last_date: null,
            reporting_company_private_data_status: 'ACCESS_CAN_BE_REQUESTED',
          },
          {
            id: Math.random(),
            company_internal_supplier_id: null,
            company_internal_supplier_name: null,
            company_name: 'RWS Web Services',
            company_slug: 'test-company-214',
            company_maturity_level_crc: 'GOOD',
            company_climate_score_overall: 10.4,
            company_public_data_screening_status: 'COMPLETED',
            company_public_data_screening_last_date: '2024-11-04',
            company_private_data_status: null,
            reporting_company_internal_supplier_id: null,
            reporting_company_internal_supplier_name: null,
            reporting_company_name: 'Rainforest.com Inc',
            reporting_company_slug: 'rainforest-group',
            reporting_company_maturity_level_crc: null,
            reporting_company_climate_score_overall: null,
            reporting_company_public_data_screening_status: 'COMPLETED',
            reporting_company_public_data_screening_last_date: null,
            reporting_company_private_data_status: null,
          },
        ]
      : data
      ? dedupeByName(data).map((d: any) => ({
          ...d,
          id: Math.random(),
        }))
      : [];

  const columns: GridColDef[] = [
    {
      field: 'company_name',
      headerName: t('Name'),
      flex: 0,
      minWidth: 190,
      sortable: false,
    },
    {
      field: 'reporting_company_name',
      headerName: t('Reporting company'),
      flex: 0,
      minWidth: 190,
      sortable: false,
    },
    {
      field: 'reporting_company_climate_score_overall',
      headerName: t('ClimateChoice Score'),
      flex: 0,
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <Box
          display={'flex'}
          height={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ScoreComponent
            score={getClimateScoreAsPercent(
              params.row?.reporting_company_climate_score_overall ||
                params.row?.company_climate_score_overall
            )}
          />
        </Box>
      ),
    },
    {
      field: 'company_maturity_level_crc',
      headerName: t('Maturity level'),
      flex: 0,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => (
        <Box
          display={'flex'}
          height={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <QualatativeDescripterChip
            type={params?.row.company_maturity_level_crc}
          />
        </Box>
      ),
    },
    {
      field: 'company_public_data_screening_status',
      headerName: t('Public data'),
      flex: 0,
      minWidth: 140,
      sortable: false,
      renderCell: (params) => (
        <Stack
          spacing={1}
          direction={'row'}
          alignItems={'center'}
          height={'100%'}
        >
          {params?.row?.company_public_data_screening_status ===
            'COMPLETED' && (
            <>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors.primary300,
                }}
              />
              <Typography variant="body1">{t('Complete')}</Typography>
            </>
          )}
          {params?.row?.company_public_data_screening_status ===
            'IN_PROGRESS' && (
            <>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors.warning100,
                }}
              />
              <Typography variant="body1">{t('In progress')}</Typography>
            </>
          )}
        </Stack>
      ),
    },
    {
      field: 'company_public_data_screening_last_date',
      headerName: t('Last refreshed'),
      flex: 0,
      minWidth: 117,
      sortable: false,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row?.reporting_company_public_data_screening_last_date ||
            params.row?.company_public_data_screening_last_date,
          new Date().toUTCString()
        );
        return (
          <Box
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            sx={{
              color: longAgo ? undefined : colors.neutral500,
            }}
            title={longAgo}
          >
            {longAgo || t('Never')}
          </Box>
        );
      },
    },
    {
      field: 'company_private_data_status',
      headerName: t('Private data'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack
          spacing={1}
          direction={'row'}
          alignItems={'center'}
          height={'100%'}
        >
          {params?.row?.company_private_data_status ===
            'ACCESS_CAN_BE_REQUESTED' && (
            <>
              <Button
                startIcon={<SendOutlined />}
                disabled={
                  useFakeData || isLoadingCreate || isLoading || isRefetching
                }
                onClick={() => {
                  if (
                    userCompany?.id &&
                    params.row?.id &&
                    confirm(
                      t(
                        'Requesting access will notify a representitive at {{target_company}}. Do you wish to continue?',
                        { target_company: params?.row.company_name }
                      )
                    )
                  ) {
                    createRequest({
                      data: {
                        data_receiver: userCompany?.id,
                        data_provider: params.row.company_id,
                      },
                      onSuccess: () => {
                        refetch();
                        snackbarMessage.addMessage({
                          message: t('Request sent'),
                        });
                      },
                    });
                  }
                }}
              >
                {t('Request')}
              </Button>
            </>
          )}
          {params?.row?.company_private_data_status === 'ACCESS_GRANTED' && (
            <>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors.primary100,
                }}
              />
              <Typography variant="body1">{t('Granted')}</Typography>
            </>
          )}
          {params?.row?.company_private_data_status === 'ACCESS_REQUESTED' && (
            <>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: colors.warning100,
                }}
              />
              <Typography variant="body1">{t('Requested')}</Typography>
            </>
          )}
        </Stack>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: (params: GridRowParams) => [
        ...(!!params.row.reporting_company_slug
          ? [
              <GridActionsCellItem
                disabled={useFakeData}
                icon={<OpenInNewIcon />}
                onClick={() =>
                  navigate(`/company/${params.row.reporting_company_slug}`)
                }
                label={t('View reporting company profile')}
                showInMenu
              />,
            ]
          : []),
        <GridActionsCellItem
          disabled={useFakeData}
          icon={<OpenInNewIcon />}
          onClick={() => navigate(`/company/${params.row.company_slug}`)}
          label={t('View company profile')}
          showInMenu
        />,
      ],
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('This collection is empty.'),
  };

  return (
    <Box minHeight={'400px'}>
      <DataGrid
        loading={!useFakeData && isLoading}
        localeText={localeText}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pageSizeOptions={[]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'company_name', sort: 'desc' }],
          },
        }}
        pagination
        paginationMode="server"
        rowSelection={false}
        onPaginationModelChange={(params: any) => setPage(params.page)}
        sx={{ minHeight: 400 }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
}

export default UserCompanyCollectionList;
